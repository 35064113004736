.btn-transparent {
  background-color: transparent;
  border: none;
  padding: 0;
}
.text-gray {
  color: #7f8299;
  font-weight: 500;
}

.header-fixed .header.toast-header {
  top: 70px;
  background: none;
  height: unset;
}

.input_prefix {
  position: relative;
  align-items: center;
  display: flex;

  &.disable {
    span {
      color: #181c32 !important;
    }

    input {
      color: #181c32;
    }
  }

  span {
    position: absolute;
    left: 20px;
    font-size: 1.15rem;
    font-weight: 500;
    color: #5e6278 !important;
  }

  input {
    padding-left: 55px !important;
    color: #5e6278;
  }
}

.min-w-100 {
  min-width: max-content;
  white-space: nowrap;
}

.max-w-100 {
  max-width: max-content;
}

.overlay-light {
  background-color: white;
  opacity: 0.5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;
}

.form-control-lg-select {
  padding-top: 3.5px;
  padding-bottom: 3.5px;
  padding-left: 9px;
  padding-right: 9px;
}

.form-check.form-check-solid .form-check-input:checked,
.form-check.form-check-solid .form-check-input.form-check-custom:checked {
  background-color: #27c0cd;
}

.form-check.form-check-solid .form-check-input.form-check-custom {
  background-color: #ec008c;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.form-time {
  background-color: #f5f8fa;
  display: flex;
  align-items: center;
  max-width: max-content;
  padding: 0.75rem 1rem;
  border-radius: 0.475rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  span {
    margin-left: 0px;
    margin-right: 5px;
  }

  input[type='time'] {
    width: max-content;
    border: 0;
    background-color: #f5f8fa;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    &:focus {
      outline: none;
      border: none;
    }

    &::-webkit-calendar-picker-indicator,
    &::-webkit-clear-button,
    *::-ms-clear {
      appearance: none;
      background: none;
      display: none;
    }
  }
}

.alert_success {
  background-color: #27c0cd;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 5px 10px rgba(63, 66, 83, 0.15);
  align-items: center;

  p {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    margin: 0 0 0 12.5px;
    padding: 0;
  }

  rect,
  path {
    fill: white !important;
  }
}

.alert_error {
  background-color: #feebf0;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 5px 10px rgba(63, 66, 83, 0.15);
  align-items: center;

  p {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #f0426c;
    margin: 0 0 0 12.5px;
    padding: 0;
  }

  rect,
  path {
    fill: #f0426c !important;
  }
}

.icon-select {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #f6f7f8;
  border-radius: 45px;
  cursor: pointer;

  &.active {
    border: 3px solid #27c0cd;
  }
}

.aside-enabled.aside-fixed.header-fixed {
  .logo-small {
    display: none;
  }
  &[data-kt-aside-minimize='on'] {
    .logo-big {
      display: none;
    }
    .logo-small {
      display: block;
    }
    .aside.aside-dark.aside-hoverable:hover {
      .logo-big {
        display: block;
      }
      .logo-small {
        display: none;
      }
    }
  }
}

.bg-gray {
  background-color: #eff2f5 !important;
}

.form-control.form-control-solid {
  &:disabled,
  &.disabled {
    background-color: #e3e6ef;
    color: #7f8299;
  }
}

.form-control.form-control-solid {
  &:disabled,
  &.disabled {
    background-color: #e3e6ef;
    color: #a1a5b7 !important;
  }
}

.custom-daterange {
  display: flex;
  align-items: center;
  position: relative;
  cursor: text;

  input {
    padding-right: 32px;
    white-space: nowrap;
  }

  .svg-calendar {
    position: absolute;
    right: 4px;
    pointer-events: none;
  }
  .svg-close {
    position: absolute;
    right: 32px;
  }
}

.custom-file-preview-dropzone {
  min-width: 150px;
  min-height: 150px;
  background-color: #e3e6ef;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
  max-width: 200px;

  span {
    background-color: #f5f8fa;
    color: #aaa;
    padding: 10px;
    border-radius: 8px;
    word-break: break-all;
  }
}

.badge-light-danger {
  background: rgba(240, 66, 108, 0.1) !important;
}

.badge-light-secondary {
  background-color: #ffe0f3;
}

.badge-light-dark {
  color: #7f8299;
}

.badge-light-warning {
  background-color: #ffe5d0;
  color: #fd7e14;
}

.badge-light-green {
  background-color: #dcf5e7;
  color: #5ad090;
}

.badge-light-purple {
  background-color: #e3d7fa;
  color: #7f4beb;
}

.btn-light-success {
  &:hover,
  &:focus {
    path {
      fill: #fff;
    }
  }
}

.custom-timerange {
  display: flex;
  align-items: center;
  background-color: #f5f8fa;
  border-radius: 0.475rem;
  padding: 0.75rem 1rem;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;

  input {
    background-color: transparent;
    border: none;
    max-width: 23px;
    font-size: 15px;

    &:focus {
      outline: none;
    }
  }

  svg {
    margin-left: 10px;
  }

  &__separator {
    margin: 0 7px;
  }

  &__hour {
    text-align: right;
  }

  &__minute {
    text-align: left;
  }
}

.svg-w-120 {
  width: 120px;
  height: 120px;
  &.svg-icon svg {
    width: 120px;
    height: 120px;
  }
}

.svg-w-40 {
  width: 40px;
  height: 40px;
  &.svg-icon svg {
    width: 40px;
    height: 40px;
  }
}

.svg-w-32 {
  width: 32px;
  height: 32px;
  &.svg-icon svg {
    width: 32px;
    height: 32px;
  }
}

.svg-w-24 {
  width: 24px;
  height: 24px;
  &.svg-icon svg {
    width: 24px;
    height: 24px;
  }
}

.svg-w-20 {
  width: 20px;
  height: 20px;
  &.svg-icon svg {
    width: 20px;
    height: 20px;
  }
}

.svg-w-16 {
  width: 16px;
  height: 16px;
  &.svg-icon svg {
    width: 16px;
    height: 16px;
  }
}

.border-dashed {
  border-style: 'dashed';
}

// TinyMCE
.tox.tox-tinymce {
  border-radius: 5px !important;
}
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background-color: #fafafa !important;
}
.tox .tox-statusbar {
  display: none !important;
}

.explore-banner {
  width: 64px;
  height: 64px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: whitesmoke;
  overflow: hidden;
}

.icon-review-content {
  width: 56px;
  height: 56px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.form-select-disabled {
  background-color: #ebedef !important;
}

.ellipsis {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.floating-container {
  position: fixed;
  left: 0;
  top: 24px;
  right: 0;
  z-index: 9999;
}

@media (min-width: 992px) {
  .floating-container {
    top: 72px;
  }
  .aside-enabled.aside-fixed {
    .floating-container {
      padding-left: 265px;
    }
    &.header-fixed[data-kt-aside-minimize='on'] .floating-container {
      padding-left: 75px;
    }
  }
}
